import 'leaflet/dist/leaflet.css'

import L from 'leaflet';

jQuery(function ($) {

    var map = L.map('map_leaflet', {
        center: [-17.53338, -149.55398],
        zoom: 12,
        scrollWheelZoom: false,
        zoomControl: false // Remove default zoom Control
    });

    var locale = $('#map_leaflet').data('locale');
    var agency_default = "Agence de Pirae";
    if (locale === 'tah'){
        agency_default = "TE PŪ NŌ PĪRA’E";
    }

    $("#agence-selected").text(agency_default);

    map.flyTo({ lat: -17.53333, lng: -149.55361 }); // move to position

    // current_maker = L.marker([item_lat, item_lng]).addTo(map).bindPopup(title_marker).openPopup();

    var popup = L.popup()
        .setLatLng([-17.53333, -149.55361])
        .setContent(agency_default)
        .openOn(map);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: ''
    }).addTo(map);

    // Add custom zoom Control to bottom-right
    L.control.zoom({
        position: 'bottomright'
    }).addTo(map);

    var blueIcon = L.icon({
        iconUrl: '/build/images/marker-icon.2273e3d8.png',
        shadowUrl: '/build/images/marker-shadow.png',

        iconSize: [25, 41], // size of the icon
        shadowSize: [41, 41], // size of the shadow
        iconAnchor: [25, 41], // point of the icon which will correspond to marker's location
    });

    // var place_description = $('.map-leaflet .place-description');
    var agence_title = $('.block_contact #agence-title');
    var agence_tel = $('.block_contact #agence-tel');
    var agence_address = $('.block_contact #agence-address');
    var agence_bp = $('.block_contact #agence-bp');
    var agence_info = $('.block_contact #agence-info');
    var menu_toggle = $('.map-leaflet .dropdown-toggle');
    var menu_container = $('.map-leaflet .dropdown-menu');
    var menu_container_mobile = $('.map-leaflet-mobile .dropdown-menu');

    var list_html = '';
    for (var x = 0; x < oph_list.length; x++) {
        var c1 = oph_list[x].position[0] || '';
        var c2 = oph_list[x].position[1] || '';
        var title_marker = oph_list[x].short_title || 'No title';
        var id_marker = oph_list[x].id || 0;
        L.marker([c1, c2], { icon: blueIcon}).addTo(map).bindPopup(title_marker);

        list_html += '<a class="dropdown-item" href="javascript:void(0)" data-id="' + id_marker + '" data-lat="' + c1 + '" data-lng="' + c2 + '">' + title_marker + '</a>'
    }

    $(menu_container).html(list_html);
    $(menu_container).niceScroll();

    $(menu_container_mobile).html(list_html);
    $(menu_container_mobile).niceScroll();

    var current_maker;
    $(".map-leaflet").on("click", ".dropdown-menu .dropdown-item", function () {
        var _this = $(this);

        var item_text = _this.text() || 'No Text';
        var item_lat = _this.attr('data-lat') || '';
        var item_lng = _this.attr('data-lng') || '';
        var item_id = _this.attr('data-id') || '';

        map.flyTo({ lat: item_lat, lng: item_lng }); // move to position

        // current_maker = L.marker([item_lat, item_lng]).addTo(map).bindPopup(title_marker).openPopup();

        var popup = L.popup()
            .setLatLng([item_lat, item_lng])
            .setContent(item_text)
            .openOn(map);

        // Change text
        let agence = oph_list.find(el => el.id === item_id);

        $(agence_title).text(agence.title);
        $(agence_tel).text(agence.tel);
        $(agence_address).text(agence.address);
        $(agence_bp).text(agence.bp);
        $(agence_info).html(agence.info);

        $(menu_toggle).text(item_text);
    });

    $(".map-leaflet-mobile").on("click", ".dropdown-menu .dropdown-item", function () {
        var _this = $(this);

        var item_text = _this.text() || 'No Text';
        var item_lat = _this.attr('data-lat') || '';
        var item_lng = _this.attr('data-lng') || '';
        var item_id = _this.attr('data-id') || '';

        map.flyTo({ lat: item_lat, lng: item_lng }); // move to position

        // current_maker = L.marker([item_lat, item_lng]).addTo(map).bindPopup(title_marker).openPopup();

        var popup = L.popup()
            .setLatLng([item_lat, item_lng])
            .setContent(item_text)
            .openOn(map);

        // Change text
        let agence = oph_list.find(el => el.id === item_id);

        $(agence_title).text(agence.title);
        $(agence_tel).text(agence.tel);
        $(agence_address).text(agence.address);
        $(agence_bp).text(agence.bp);
        $(agence_info).html(agence.info);

        $(menu_toggle).text(item_text);
    });

    var initBlockContact = function initBlockContact() {
        let agence = oph_list.find(el => el.id === '1');
        if(agence) {
            $(agence_title).text(agence.title);
            $(agence_tel).text(agence.tel);
            $(agence_address).text(agence.address);
            $(agence_bp).text(agence.bp);
            $(agence_info).html(agence.info);
        }
    };
    initBlockContact();

});

var execute = 0;
$('#map_leaflet').find('.dropdown-menu').on('mouseenter', function(){
    var locale = $(this).data('locale');
    $('.dropdown-menu').find(".dropdown-item").click(function() {
        var agenceId = $(this).data('id');
        // var islandName = $("#demande_geographicalIsland option")[islandId].text;
        // var townSelected = $("#demande_geographicalTown").find(':selected').text();
        $.ajax({
            url: "/get-agence-infos",
            type: "GET",
            dataType: "JSON",
            data: {
                agence: agenceId,
                locale: locale
            },
            success: function (data) {
                // execute ++;
                // if (execute>1) {
                //     return false;
                // } else {
                $('#agence-info').html(data.agence);
                // }
            },
            error: function (err) {
                // $('.loading-ajax').fadeOut();
                console.log("loading data ...");
            }
        });
    });
});

